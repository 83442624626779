import React, { useRef, useEffect,useState } from 'react';
import "./styles.scss";
import Header from "../../Components/Header";
import { Button, Typography } from "@mui/material";
import { Dialog, DialogContent, IconButton } from '@mui/material';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import logo from "../../Assets/PromotionImage/logo.png";
import imgDetails from "../../Assets/PromotionImage/image_details.png";
import GoogleMap from "../../Components/GoogleMap";
import Footer from "../../Components/Footer";
import { ApiCall,ApiMethod } from "../../services/AxiosInstance";
import getImageUpdload from "../../Utilities/getImageUpload";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Fade } from '@mui/material';
import Loader from "../../Components/Loader";
import Slider from "react-slick";
const moment = require('moment');

const PromotionPage = () => {
  const [resDetails,setResDetails] = useState([])
  const [imageGallery,setImageGallery] = useState([])
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading,setIsLoading] = useState(false)
  const params = useParams()
  const navigate = useNavigate()

  console.log("paramsssssss",params)
  const restaurant_id = params?.restaurant_id
  let SliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // initialSlide: 1
  };
  useEffect(()=>{
    if(restaurant_id){
        getRestaurantDetails()
    }
 
  },[restaurant_id])



  const getRestaurantDetails = async()=>{
    try{
         setIsLoading(true)
         const response = await ApiCall({
            apiEndpoint:`/items/restaurant/${restaurant_id}?fields=*,page_setting.*,page_setting.promotion_deals.*,page_setting.branches.*,page_setting.vouchers.*,image_gallery.*`,
            method:ApiMethod.GET,
            // token:restaurantData.access_token,
            service:true,
          })
        
         const result = response.data.data
         setResDetails(result)
         setImageGallery(result?.image_gallery)
         setIsLoading(false)
         console.log("this is the resulttt",result)

    }catch(err){
      setIsLoading(false)
        console.log("getRestaurantDetails err",err)
    }
  }


  const convertTime = (dateString) =>{
    const date = moment(dateString);
    const formattedTime = date.format('h:mm A');
    return formattedTime
  }

  const convertDate = (dateString,dateTime = false) =>{
    if(dateTime){
        const formattedDateTime = moment(dateString).format('MM/DD/YYYY HH:mm');
        return formattedDateTime
    }else{
        const formattedDate = moment(dateString).format('MMM D, YYYY');
        return formattedDate
    }

   
  }
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        overflowX:'hidden'
      }}
    >
      {/* Todo here */}
      <Header
        containerStyle={{ position: "relative" }}
        headerText={"Promotions & Deals"}
      />
      <div className="promotion_page_container">
        <div className="content_page_container">
          <div className="left_side">
            <div className="img_container">
              <div className="partner_logo" style={{backgroundImage:`url(${getImageUpdload(resDetails?.res_logo)})`}}>

              </div>
              {/* <img src={} /> */}
            </div>
            <Typography className="restaurant_label">
              restaurant details
            </Typography>
            {/* <div className="opening_time">
              <AccessTimeIcon className="first" />
              <Typography className="second">
                <b style={{ color: "#F0522B" }}>Open from</b> {convertTime(resDetails?.page_setting?.opening_time)} - {convertTime(resDetails?.page_setting?.closing_time)}
              </Typography>
            </div> */}
            {/* for showing only in mobile */}
            
            <div className="right_side">
            <Typography className="first">Location</Typography>
            <Typography className="second">Branches</Typography>
            <Typography className="third">
              {resDetails?.page_setting?.branches.length > 0  ? "You can check our other branches :" : "Currently no available branches in this restaurant :"}  
              {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis */}
            </Typography>
            {resDetails?.page_setting?.branches.map((item)=>{
                return  <div className="address_container">
                <LocationOnIcon className="location_icon" onClick={()=>{
                     const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${item?.addr_line_1} ${item?.addr_line_2} 7/11`)}`;
                     window.open(mapsUrl, '_blank');
                }}/>
                <div className="address_desc">
                  <Typography>{item?.name}</Typography>
                
                  {/* <Typography>Address</Typography> */}
                  <Typography>
                    {item?.addr_line_1},<br/> {item?.addr_line_2}, <br/> {item?.addr_line_3}
                  </Typography>
                  <Typography>
                  <b style={{ color: "#F0522B" }}>Open from:</b> {convertTime(item?.opening_time)} - {convertTime(item?.closing_time)}
                  </Typography>

                  <Button
                    variant="contained apply_theme"
                    className="reservation_btn"
                    onClick={()=>navigate(`/reservations/${restaurant_id}/${item?.id}`)}
                  >
                  Make A Reservation Now
                 </Button>
                </div>
              </div>
            })}
            {/* {[0, 1, 2, 3, 4, 5].map(() => {
              return (
                <div className="address_container">
                  <LocationOnIcon className="location_icon" />
                  <div className="address_desc">
                    <Typography>Address</Typography>
                    <Typography>
                      Your address here, City, Country 0000
                    </Typography>
                  </div>
                </div>
              );
            })} */}
           </div>

            <div className="restaurant_desc">
          
            {/* {typeof resDetails?.page_setting?.details} */}
              <div className="first">
              <div dangerouslySetInnerHTML={{ __html: resDetails?.page_setting?.details }} />
                {/* {resDetails?.page_setting?.details} */}
              </div>
              {/* <Typography className="second">
                Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                aut fugit, sed quia consequuntur magni dolores eos qui ratione
                voluptatem sequi nesciunt.
              </Typography>
              <Typography className="third">
                Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, sed quia non numquam eius modi
                tempora incidunt ut labore et dolore magnam aliquam quaerat
                voluptatem.
              </Typography> */}
            </div>
            {imageGallery.length > 0 &&  <div className="img_containers">
              {imageGallery.length > 0 &&  imageGallery?.map((item,index)=>{
                 return <div className="first_" style={{backgroundImage:`url(${getImageUpdload(item?.image)})`}} onClick={()=>{
                  //setSelectedImage(item.image);
                  setSelectedImage(index)
                  setOpen(true);
                  // SliderSettings = {
                  //   ...SliderSettings,
                  //   initialSlide: index // Change the value to the index of the desired initial slide
                  // };
                 
                 }}>
                  {/* <img src={getImageUpdload(item?.image)} onClick={()=>{
                  setSelectedImage(item.image);
                  setOpen(true);
                 }} /> */}
                 
                 </div> 
              }) }
              {/* {[0, 1, 2, 3, 4, 5].map(() => {
                return <img src={imgDetails} />;
              })} */}
            </div>
}
           
            {/* <div className="btn_details">
              <Button
                variant="contained apply_theme"
                className="reservation_btn"
                onClick={()=>navigate(`/reservations/${restaurant_id}`)}
              >
                Make A Reservation Now
              </Button>
            </div> */}
          </div>
          <div className="right_side">
            <Typography className="first">Location</Typography>
            <Typography className="second">Branches</Typography>
            <Typography className="third">
            {resDetails?.page_setting?.branches.length > 0  ? "You can check our other branches :" : "Currently no available branches in this restaurant :"}  
              {/* Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis */}
            </Typography>
            {resDetails?.page_setting?.branches.map((item)=>
                item.status !=='archived' &&  <div className="address_container">
                <LocationOnIcon className="location_icon" style={{cursor:'pointer'}} onClick={()=>{
                     const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${item?.addr_line_1} ${item?.addr_line_2} 7/11`)}`;
                     window.open(mapsUrl, '_blank');
                }}/>
                <div className="address_desc">
                  <Typography>{item?.name}</Typography>
                
                  {/* <Typography>Address</Typography> */}
                  <Typography>
                    {item?.addr_line_1},<br/> {item?.addr_line_2}, <br/> {item?.addr_line_3}
                  </Typography>
                  <Typography>
                  <b style={{ color: "#F0522B" }}>Open from:</b> {convertTime(item?.opening_time)} - {convertTime(item?.closing_time)}
                  </Typography>

                  <Button
                    variant="contained apply_theme"
                    className="reservation_btn"
                    onClick={()=>navigate(`/reservations/${restaurant_id}/${item?.id}`)}
                  >
                  Make A Reservation Now
                 </Button>
                </div>
              </div>
            )}
            {/* {[0, 1, 2, 3, 4, 5].map(() => {
              return (
                <div className="address_container">
                  <LocationOnIcon className="location_icon" />
                  <div className="address_desc">
                    <Typography>Address</Typography>
                    <Typography>
                      Your address here, City, Country 0000
                    </Typography>
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
      {/* <GoogleMap  address={resDetails?.res_addr}/> */}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#EDEDED",
          maxWidth: "1920px",
        }}
      >
        <div className="promotion_offer_container">
         {
          resDetails?.page_setting?.promotion_deals[0]?.eff_from && resDetails?.page_setting?.promotion_deals[0]?.eff_to &&
          resDetails?.page_setting?.promotion_deals[0]?.eff_to > formattedDateTime? <>
            {/* <div className="first_layer_">
            <Typography className="first">{parseInt(resDetails?.page_setting?.promotion_deals[0]?.discount)}% off</Typography>
            <Typography className="second">{resDetails?.page_setting?.promotion_deals[0]?.restaurant_name}</Typography>
          </div> */}
          <div className="second_layer">
            <div className="left">
            <Typography className="first_">{parseInt(resDetails?.page_setting?.promotion_deals[0]?.discount)}% off</Typography>
            <Typography className="second_">{resDetails?.page_setting?.promotion_deals[0]?.restaurant_name}</Typography>
              <Typography className="first">Promo Offer</Typography>
              <Typography className="second">
              - {parseInt(resDetails?.page_setting?.promotion_deals[0]?.discount)}% OFF applicable to all menus 
              </Typography>
              {/* <Typography className="second">
                20% OFF with Minimum spend of P5,000
              </Typography>

              <Typography className="third">
                - min. spend of Php 1,000
              </Typography>
              <Typography className="third">
                - max. spend of Php 10,000
              </Typography>
              <Typography className="third">
                - valid for dine-in and take-out
              </Typography>
              <Typography className="third">
                - valid from Monday to Sunday
              </Typography>
              <Typography className="third">
                - valid all day during store operations
              </Typography> */}
            </div>
            <div className="right">
              <Typography className="first">
              <div dangerouslySetInnerHTML={{ __html: resDetails?.page_setting?.promotion_deals[0]?.details }} />
               {}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. */}
              </Typography>
              {/* <Typography className="second">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Typography> */}
            </div>
          </div>
          <div className="third_layer">
            <div className="left_side">
              <div>
                <Typography className="first">From</Typography>
                <Typography className="second">{convertDate(resDetails?.page_setting?.promotion_deals[0]?.eff_from)}</Typography>
              </div>
            </div>
            <div className="right_side">
              <div>
                <Typography className="first">To</Typography>
                <Typography className="second">{convertDate(resDetails?.page_setting?.promotion_deals[0]?.eff_to)}</Typography>
              </div>
            </div>
          </div>
         </> : <Typography className="no_promo" >No Promo Available</Typography>}
       
        </div>
      </div>

      {isLoading && <Loader/>} 

      {/* <div className="vouchers_container">
        <Typography className="voucher_label">Vouchers & promotions</Typography>
        <div className="vouchers_list_container">
            {resDetails?.page_setting?.vouchers.length > 0 && resDetails?.page_setting?.vouchers.map((item)=>{
                return (
                    <div className="preview">
                      <Typography className="off">{item?.discount}% OFF</Typography>
                      <div className="header">
                        <Typography className="pc">Code: {item?.promo_code}</Typography>
                        <Typography className="dl">Download</Typography>
                      </div>
                      <div className="listing">
                        <div>
                          <Typography>
                            - {convertDate(item?.eff_from,true)} - {convertDate(item?.eff_to,true)}
                          </Typography>
                        </div>
                        <div>
                          <Typography>-  {parseInt(item?.discount)}% OFF applicable to all menus</Typography>
                        </div>
                        <div>
                     
                        </div>
                      </div>
                    </div>
                  );
            }) }

         
        </div>
       </div> */}

            <Modal open={open}
             onClose={()=>setOpen(false)}
             aria-labelledby="loader-modal-title"
             aria-describedby="loader-modal-description"
             className="loader_modal"
             closeAfterTransition // closes modal after transition animation
             BackdropProps={{
                 timeout: 500, // adjust transition duration here
             }}
             
             >
                   <Fade in={open}>

                   <div className="modal_shesh">
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={()=>setOpen(false)}
                        aria-label="close"
                        style={{ position: 'absolute', top: "10px", right: "10px",fontSize:"40px",color:"red",zIndex:1}}
                    >
                        <CloseIcon />
                    </IconButton>
                    {/* <span>{JSON.stringify(SliderSettings)}</span> */}
                    <Slider {...{
                            dots: false,
                            infinite: true,
                            speed: 500,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true,
                            initialSlide: selectedImage ,
                            // Initial value
                      }} >
                      {
                        imageGallery.map((item)=>
                          <div>
                            <div  className="sliderImgCon" style={{backgroundImage:`url(${getImageUpdload(item?.image)})`}}></div>
                            {/* <span>{JSON.stringify(item)}</span> */}
                            {/* <img src={getImageUpdload(item?.image)} alt="Full Size" style={{ width: '100%', height: '100%',borderRadius:"20px" }} /> */}
                          </div>
                        )
                      }
                    </Slider>
                    {/* <Slider {...SliderSettings}>
                      <div>
                        <img src={getImageUpdload(selectedImage)} alt="Full Size" style={{ width: '100%', height: '100%',borderRadius:"20px" }} />
                      </div>
                      <div>
                        <img src={getImageUpdload(selectedImage)} alt="Full Size" style={{ width: '100%', height: '100%',borderRadius:"20px" }} />
                      </div>
                      </Slider> */}
                    {/* <img src={getImageUpdload(selectedImage)} alt="Full Size" style={{ width: '100%', height: '100%',borderRadius:"20px" }} /> */}
                </div>
                   </Fade>
                
            </Modal>

      <Footer />
    </div>
  );
};

export default PromotionPage;
