import { Button, Typography } from '@mui/material';
import React, { useState,useEffect } from 'react';
import footer_image1 from  "../../Assets/FooterImages/footer_image1.png"
import footer_image2 from "../../Assets/FooterImages/footer_image2.png"
import footer_logo from "../../Assets/FooterImages/footer_logo.png"
import footerbg from "../../Assets/FooterImages/footer_bg.png"
import "./styles.scss"
import { useNavigate } from 'react-router-dom';
import EventEmitter from "reactjs-eventemitter";
const Footer = ({isFooter,isContact,reqDemo,finRes}) => {
const navigate =useNavigate()


  return (
        <div className="footer_container" style={{backgroundImage:isFooter && `url(${footerbg})`,borderTopLeftRadius:isFooter ? '50px' : 0,backgroundSize:'cover'}}>
            <div className='secondary_container'>
                <div className='first'>
                    <div className='left_side'>
                        <div className='text_container'>
                        <Typography className='first'>From Customer to Kitchen</Typography>
                        <Typography className='second_'>Seamlessly Coordinate Orders for a Smooth Dining Experience</Typography>
                        <Typography className='third'>Provide restaurants with valuable insights into customer behavior, order trends, and popular menu items.</Typography>
                         
                        <Button variant="contained apply_theme" style={{backgroundColor:"#F0522B",color:'white',width:"242px",marginTop:"20px",padding:"10px"}} onClick={isContact?  reqDemo:()=>{
                            navigate('/contact-us/form')
                            }}>Request Demo</Button>
                        </div>
                      
                    </div>
                    <div className='right_side' style={{backgroundImage:`url(${footer_image1})`}}>
                            <img src={footer_image2} className='img_2' />
                    </div>
                </div>
                <div className='second'>
                     <div className='logo_container' style={{backgroundImage:`url(${footer_logo})`,cursor:'pointer'}} onClick={()=>navigate('/')} >

                     </div>
                     <Typography className='cutting_edge'>Cutting-Edge Solutions for Seamless Dining of <br/> Our QR Order System Services</Typography>
                    <div className='list' style={isFooter ? {justifyContent:'center',color:"white"} : {justifyContent:'space-between',color:'#2B2B2B'}}>
                        {isFooter ?   <Typography className='list_item' onClick={reqDemo}>Contact Us</Typography> : <>
                        <Typography className='list_item' onClick={()=>navigate('/')}>Home</Typography>
                        <Typography className='list_item' onClick={()=>navigate('/contact-us')}>Contact Us</Typography>
                        <Typography className='list_item' onClick={()=>EventEmitter.dispatch('findRes')}>Find Restaurant</Typography>
                        </>
                        }
                       
                    </div>

                    <Typography className='watermark' style={{color:isFooter? "white" : '#000000'}}>© EZOda 2024</Typography>
                </div>

                
            </div>  
          
        </div>
  );
};

export default Footer;
