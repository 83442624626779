import React, { useEffect } from 'react'
import "./styles.scss"
import Header from '../../Components/Header'
import logo from "../../Assets/PromotionImage/logo.png"
import Footer from '../../Components/Footer'
import { Button, TextField, Typography } from '@mui/material'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react'
import { Tab, Tabs, Paper } from '@mui/material';
import { ApiCall, ApiMethod } from '../../services/AxiosInstance'
import { useParams } from 'react-router-dom';
import SnackBar from '../../Components/SnackBar'
import moment from 'moment';
import Loader from '../../Components/Loader'
import getImageUpdload from '../../Utilities/getImageUpload'
const Reservations = () => {

    const params = useParams()

    const [date, setDate] = useState(new Date());
    const restaurant_id = params?.restaurant_id
    const branch_id = params?.branch_id
  const [restDet,setRestDet] = useState([])
    const getRestDet=async()=>{
     await ApiCall({
        apiEndpoint:`/items/restaurant/${restaurant_id}`,
        method:ApiMethod.GET,
        service:true
      }).then((res)=>{
        const resData = res.data.data
        setRestDet(resData)
      }).catch((err)=>{
        console.log("Error Fetching Restaurant Details", err)
      })

    }

   useEffect(()=>{
    getBranchDetails()
    getRestDet()
   },[branch_id])

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTime, setSelectedTime] = useState('');
  const [isEmptyTime,setIsEmptyTime] = useState(false)
  const [invalidEmail,setInvalidEmail] = useState(false)
  const [branchDetails,setBranchDetails] = useState([])
  const [range,setRange] =  useState([])
  const [isLoading,setIsLoading] = useState(false)
  const [isSuccess,setIsSuccess] = useState(false)

  
  const getBranchDetails = async () => {
    try {
        const response = await ApiCall({
            apiEndpoint: `/items/branches/${branch_id}`,
            method: ApiMethod.GET,
            // token:restaurantData.access_token,
            service: true,
        });
        const result = response.data.data;
        // Extract hours and minutes from opening and closing times
        const openingHours = moment(result.opening_time).hours();
        const openingMinutes = moment(result.opening_time).minutes();
        const closingHours = moment(result.closing_time).hours();
        const closingMinutes = moment(result.closing_time).minutes();

        // Generate time range with 30-minute interval
        const timeRange = [];
        let currentTime = moment().set({ hours: openingHours, minutes: openingMinutes }); // Set current time based on opening hours and minutes
        const closingTime = moment().set({ hours: closingHours, minutes: closingMinutes }); // Set closing time

        console.log("Closing Time:", closingTime.format('h:mm A'));
        console.log("Current Time:", currentTime.format('h:mm A'));
        
        // Loop to generate time range
        while (currentTime.isSameOrBefore(closingTime)) {
            timeRange.push(currentTime.format('h:mm A')); // Use 'h:mm A' for 12-hour clock format with AM/PM indicator
            currentTime.add(30, 'minutes');
        }

        console.log("Generated time range:", timeRange);
        setRange(timeRange);
    } catch (err) {
        console.log("getBranchDetails err", err);
    }
};






  const TabList = ["BREAKFAST","LUNCH","DINNER"]

    const handleChangeTab = (event, newValue) => {
      console.log("tghissss is the eveenntttttt",TabList[newValue])
        setSelectedTab(TabList[newValue]);
    };

    const handleTimeSelect = (time) => {
        setIsEmptyTime(false)
        setSelectedTime(time);
    };


    const [formData,setFormData] = useState({
            name:null,
            email_address:null,
            contact_number:null,
            address_line_1:null,
            address_line_2:null,
            reservation_date:new Date(),
            number_of_person:null
            // time_slot:"null"
    })

    const [inputErrors, setInputErrors] = useState({
        name: false,
        email_address: false,
        contact_number: false,
        address_line_1: false,
        address_line_2: false,
        number_of_person:false
        
    });

    const onChange = (newDate) => {
        setFormData({...formData,reservation_date:newDate})
        setDate(newDate);
       
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const onChangeText = (key, value) => {
        setFormData(prevState => ({
          ...prevState,
          [key]: value
        }));
        setInputErrors(prevState => ({
            ...prevState,
            [key]: false // Reset error state when input changes
          }));
      };

    const handleInputChange = (event, key) => {
      if(key === 'email_address'){
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = emailRegex.test(event.target.value);
    
        if (isValidEmail) {
          onChangeText(key, event.target.value);
          setInvalidEmail(false);
        } else {
          onChangeText(key, event.target.value);
          setInvalidEmail(true);
        }
      }else{
        onChangeText(key, event.target.value);
      }
       
      };

     
    const submitReservation = async()=>{
     
        const errors = {};
        Object.keys(formData).forEach(key => {
            if (!formData[key]) {
            errors[key] = true;
            }
        });
        setInputErrors(errors);
        if(selectedTime === ''){
            setIsEmptyTime(true)
            return
        }
        if(invalidEmail){
          return
        }

        if (Object.keys(errors).length === 0) {
         
            console.log("Form submitted:", formData);

            try{
               setIsLoading(true)
                const response = await ApiCall({
                    apiEndpoint:`/items/Reservations`,
                    method:ApiMethod.POST,
                    // token:restaurantData.access_token,
                    service:true,
                    apiData:{
                        ...formData,restaurant:restaurant_id,time_slot:selectedTime,branch:branch_id
                    }
                  })
                const result = response.data.data
                setFormData({
                  name:"",
                  email_address:"",
                  contact_number:"",
                  address_line_1:"",
                  address_line_2:"",
                  reservation_date:new Date(),
                  number_of_person:""
                  // time_slot:"null"
                 })
                 setSelectedTime('')
                setIsLoading(false)
                setIsSuccess(true)
                setTimeout(()=>{
                  setIsSuccess(false)
                },3000)
                
                console.log("thiss is ther resulttt",result)
            }catch(err){
               setIsLoading(false)
                console.log("submitReservation err",err)
            }

        }

    }

    const currentTime = moment();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  return (
    <div style={{width:"100%",display:"flex",alignItems:'center',flexDirection:'column'}}>
        <Header headerText={"Make a Reservation"} containerStyle={{position:"relative"}}/>
        <div className='reservation_container'>
            
            <div className='logo_section'>
            <div className="partner_logo" style={{backgroundImage:`url(${getImageUpdload(restDet?.res_logo)})`}}>

              </div>
              {/* <img src={getImageUpdload(restDet?.res_logo)}/> */}
            </div>
            <div className='content_container'>

            <div className='form_container'>
                <Typography className='fill_up_details'>Fill up details</Typography>
                <CustomInput label={"Full Name"} value={formData.name} onChange={(e) => handleInputChange(e, 'name')} error={inputErrors.name} />
                <CustomInput label={"Email Address"} value={formData.email_address} onChange={(e) => handleInputChange(e, 'email_address')} invalidEmail={invalidEmail} error={inputErrors.email_address} />
                <CustomInput label={"Contact Number"} type="number" value={formData.contact_number} onChange={(e) => handleInputChange(e, 'contact_number')} error={inputErrors.contact_number} />
                <CustomInput label={"Number of Person's"} type="number" value={formData.number_of_person} onChange={(e) => handleInputChange(e, 'number_of_person')} error={inputErrors.number_of_person} />
                <CustomInput label={"Address Line 1"} value={formData.address_line_1} onChange={(e) => handleInputChange(e, 'address_line_1')} error={inputErrors.address_line_1} />
                <CustomInput label={""} value={formData.address_line_2} onChange={(e) => handleInputChange(e, 'address_line_2')} error={inputErrors.address_line_2} />
                <Button variant="contained apply_theme" className='btn_kak' onClick={()=>submitReservation()}>Submit</Button>
            </div>
            
            <div className='calendar_container'>
                  
                    <div className='first'>
                    <Typography className='choose_date'>Choose date</Typography>
                    <div className='calendar_container2'>
                      <Calendar
                          style={{backgroundColor:"blue"}}
                          onChange={onChange}
                          minDate={new Date()}
                          value={formData?.reservation_date}
                      />
                    </div>
                    </div>

                    <div className='second'>
                      <Typography className='choose_date'>Choose time slot</Typography>
                      {isEmptyTime && <Typography style={{color:'red',fontWeight:"700"}}>Please Select a Time Slot</Typography>} 
                      {range.map(hour => (
                        !moment(hour, 'h:mm A').isBefore(currentTime) &&
                          <Button
                            key={hour}
                            variant="outlined"
                            style={{
                                marginRight: "15px",
                                marginTop: "10px",
                                backgroundColor: selectedTime === hour ? "#F0522B" : "inherit",
                                color: selectedTime === hour ? "white" : "inherit",
                                border: selectedTime === hour && "none",
                                pointerEvents: moment(hour, 'h:mm A').isBefore(currentTime) ? "none" : "auto", // Disable button if time is past current time
                                opacity: moment(hour, 'h:mm A').isBefore(currentTime) ? 0.5 : 1 // Optionally, reduce opacity for past times
                            }}
                            onClick={() => handleTimeSelect(hour)}
                            disabled={moment(hour, 'h:mm A').isBefore(currentTime)}
                        >
                            {hour}
                        </Button> 
                        
                        
                    ))}
                      {/* <Paper square>
                          <Tabs
                              value={selectedTab}
                              onChange={handleChangeTab}
                              indicatorColor="primary"
                              textColor="primary"
                              centered
                          >
                            {TabList.map((item )=>{
                              return <Tab label={item} />
                            })}
                              
                            
                          </Tabs>
                      
                          <TabPanel value={selectedTab} index={0}>
                              {[8, 9, 10, 11, 12].map(hour => (
                                  <Button key={hour} variant="outlined" style={{ marginRight: "15px", marginTop: "10px" }} onClick={() => handleTimeSelect(`${hour}:00 AM`)}>
                                      {hour}:00 AM
                                  </Button>
                              ))}
                          </TabPanel>
                          <TabPanel value={selectedTab} index={1}>
                              {[1, 2, 3, 4, 5].map(hour => (
                                  <Button key={hour} variant="outlined" style={{ marginRight: "15px", marginTop: "10px" }} onClick={() => handleTimeSelect(`${hour}:00 PM`)}>
                                      {hour}:00 PM
                                  </Button>
                              ))}
                          </TabPanel>
                          <TabPanel value={selectedTab} index={2}>
                              {[6, 7, 8, 9, 10].map(hour => (
                                  <Button key={hour} variant="outlined" style={{ marginRight: "15px", marginTop: "10px" }} onClick={() => handleTimeSelect(`${hour}:00 PM`)}>
                                      {hour}:00 PM
                                  </Button>
                              ))}
                          </TabPanel>
                      </Paper> */}
                    </div>
             
              
            </div>
            
            </div>

           
        </div>
        <Footer/>
        <SnackBar message={"Reservations succesfully sent!"} isOpen={isSuccess} handleClose={()=>setIsSuccess(false)}/>
       {isLoading && <Loader/>}  
       
    </div>
  )

}

const CustomInput = ({ label, value, onChange, error,type,invalidEmail }) => {
    return (
      <div className="input_custom" style={{width:'100%',}}>
        <Typography style={{marginBottom:"10px",color:"#F0522B",fontSize:'18px',marginTop:"10px"}}>{label}</Typography>
        <TextField className='form_fields' type={type} value={value} onChange={onChange} error={error ? error : invalidEmail} style={{width:'100%',margin:0}}/>
        {invalidEmail && <Typography style={{color: 'red',fontWeight:"700",marginTop:"3px"}}>Email is invalid</Typography>}
        {error && <Typography style={{color: 'red',fontWeight:"700",marginTop:"3px"}}>This field is required</Typography>}
      </div>
    );
  }


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
       style={{minHeight:"130px",height:"auto",padding:'10px',borderRadius:"10px"}}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
  }
  


export default Reservations

