import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

const SnackBar = ({contentStyle = {backgroundColor: 'green',width:"500px",justifyContent:"center",display:'flex',fontSize:"24px"},autoHideDuration=3000,isOpen,handleClose,message}) => {
  return (
    <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    open={isOpen}
    autoHideDuration={autoHideDuration}
    onClose={()=>handleClose()}
>
    <SnackbarContent
        style={contentStyle}
        message={message}
    />
    </Snackbar>
  )
}

export default SnackBar
