import React from 'react'
import "./styles.scss"
import Typography from '@mui/material/Typography';
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import Carousel from 'react-elastic-carousel';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Frame from "../../Assets/partners_logos/Frame.png";
import Frame1 from  "../../Assets/partners_logos/Frame1.png";
import Frame3 from  "../../Assets/partners_logos/Frame3.png";
import Frame4 from  "../../Assets/partners_logos/frame4.png";
import Frame5 from  "../../Assets/partners_logos/Frame5.png";
import Frame6 from  "../../Assets/partners_logos/Frame6.png";
import Frame7 from  "../../Assets/partners_logos/Frame7.png";


import icon1 from "../../Assets/mini_icons/icon1.png"
import icon2 from "../../Assets/mini_icons/icon2.png"
import icon3 from "../../Assets/mini_icons/icon3.png"
import icon4 from "../../Assets/mini_icons/icon4.png"
import icon5 from "../../Assets/mini_icons/icon5.png"

import icon01 from "../../Assets/mini_icons/icon01.png"
import icon02 from "../../Assets/mini_icons/icon02.png"
import icon03 from "../../Assets/mini_icons/icon03.png"
import icon04 from "../../Assets/mini_icons/icon04.png"
import icon05 from "../../Assets/mini_icons/icon05.png"


import Footer from '../../Components/Footer';
import { Button } from '@mui/material';
import Header from '../../Components/Header';
import { ApiCall ,ApiMethod} from '../../services/AxiosInstance';
import { useEffect,useState } from 'react';
import getImageUpdload from '../../Utilities/getImageUpload';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Loader from '../../Components/Loader';
import SnackBar from '../../Components/SnackBar';

const HomePage = () => {

const [restaurants,setRestaurants] = useState([])
const [isLoading,setIsLoading] = useState(false)
const [originalDetail,setOriginalDetails] = useState([])
const [currentWidth,setCurrentWidth] = useState("1440px")
const navigate = useNavigate()


 useEffect(()=>{
    getAllRestaurants()
 },[])

   const getAllRestaurants = async() =>{
      try{
        setIsLoading(true)
        const response = await ApiCall({
          apiEndpoint:`/items/restaurant?fields=*&filter[status][_eq]=published`,
          method:ApiMethod.GET,
          // token:restaurantData.access_token,
          service:true,
        })
      
       const result = response.data.data
       console.log("restaurants",result)
       setOriginalDetails(result)

       const groupedRestaurants = [];
        for (let i = 0; i < result.length; i += 2) {
          const item1 = result[i];
          const item2 = result[i + 1];
          const pair = {};
          pair[`item_1`] = item1;
          if (item2) {
            pair[`item_2`] = item2;
          }
          groupedRestaurants.push(pair);
        }

        // let duplicatedRestaurants = result.slice();

        // while (duplicatedRestaurants.length < 7) {
        //     duplicatedRestaurants = duplicatedRestaurants.concat(result);
        // }
       if(result.length > 5){
        setRestaurants(groupedRestaurants)
       }else{
        setRestaurants(result)
       }
     
       setIsLoading(false)
      }catch(err){
        setIsLoading(false)
        console.log("getAllRestaurants err",err)
      }
   }

    // const logos = [
    //     Frame,
    //     Frame1,
    //     Frame3,
    //     Frame4,
    //     Frame5,
    //     Frame6,
    //     Frame7,  
    //   ]
      
      const listing = [
        {
          image:icon1,
          label:'Digital Menu Display',
          desc:"Create dynamic digital menus that can be easily accessed via QR codes."
        },
        {
          image:icon2,
          label:'Contactless Ordering',
          desc:"Enable customers to browse the menu, place orders, and make payments directly from their smartphones."
        },
        {
          image:icon3,
          label:'Order Customization',
          desc:"Allow customers to customize their orders according to their preferences, such as selecting toppings, choosing sides, or specifying dietary requirements."
        },
        {
          image:icon4,
          label:'Integration with POS System',
          desc:"Seamlessly integrate the QR order system with the restaurant's Point of Sale (POS) system."
        },
        {
          image:icon5,
          label:'Real-Time Order Tracking',
          desc:"Provide customers with the ability to track the status of their orders in real-time. "
        }
      ]

      const listing2 = [
        {
          image:icon01,
          label:'Kitchen Display System (KDS) Integration',
          desc:"Integrate the QR order system with a digital kitchen display system to provide real-time order updates to kitchen staff."
        },
        {
          image:icon02,
          label:'Item Preparation Alerts',
          desc:"Notify kitchen staff when new orders are received and alert them when it's time to start preparing specific items."
        },
        {
          image:icon03,
          label:'Order Status Updates',
          desc:"Provide kitchen staff with real-time updates on the status of each order, including when orders are received, in progress, and ready for serving."
        },
        {
          image:icon04,
          label:'Order Routing',
          desc:"Direct orders from the QR system to the appropriate stations in the kitchen, ensuring efficient workflow."
        },
        {
          image:icon05,
          label:'Contactless Payment Options',
          desc:"Support a variety of contactless payment methods, including mobile wallets, credit/debit cards, and online payments."
        }
      ]
      
      const CustomLeftArrow = ({ onClick }) => (
        <ArrowBackIosNewIcon onClick={onClick}  style={{position:"absolute",bottom:"40%",zIndex:3,fontSize:"50px",padding:"10px",borderRadius:50,backgroundColor:"white",border:"1px solid lightgray"}} />
        
      );
    
      const CustomRightArrow = ({ onClick }) => (
        <ArrowForwardIosIcon onClick={onClick}  style={{position:"absolute",bottom:"40%",right:0,zIndex:3,fontSize:"50px",padding:"10px",borderRadius:50,backgroundColor:"white",border:"1px solid lightgray"}}/> 
      );

      const [centerSlidePercentage, setCenterSlidePercentage] = useState(20); // Default percentage

      useEffect(() => {
        const handleResize = () => {
          const windowWidth = window.innerWidth;
          setCurrentWidth(windowWidth)
          // Calculate the percentage based on window width
          if (windowWidth <= 540) {
            setCenterSlidePercentage(30);
          } else if (windowWidth <= 980) {
            setCenterSlidePercentage(25);
          } else {
            setCenterSlidePercentage(20);
          }
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
        // Call handleResize initially
        handleResize();
    
        // Cleanup on unmount
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    

      const items =  [
        {id: 1, title: 'item #1'},
        {id: 2, title: 'item #2'},
        {id: 3, title: 'item #3'},
        {id: 4, title: 'item #4'},
        {id: 5, title: 'item #5'}
      ]

      useEffect(() => {
        window.scrollTo(0, 0);
      })
  return (
    <>
    
    <div style={{display:'flex',alignItems:'center',flexDirection:"column"}}>
       <Header containerStyle={{height:'auto',backgroundImage:"none"}}/>
        <div style={{width:"100%",display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',maxWidth:"1920px",alignSelf:'center'}}>
        <div className='main_banner'>
                <div className='left'>
                    <div className='left_content'>
                        <Typography className='first'>Effortless Dining, Elevated Experience:</Typography>
                        <Typography className='second'>Order with Ease,<br/>Enjoy with Delight</Typography>
                        <Typography className='third'>Revolutionize your dining experience with our seamless QR order system, simplifying service and enhancing satisfaction, one scan at a time.</Typography>
                        <Button variant="contained apply_theme" style={{backgroundColor:"#F0522B",color:'white',width:"242px",marginTop:"20px",padding:"10px"}} onClick={()=>navigate(`/contact-us/form`)}>Contact Us</Button>
                    </div>
                </div>
            
        </div>
        <div className='first_layer'>
        <div className='label_container'>
          <Typography className='newlabel'>restaurant partners</Typography>
        </div>
        
        {/* <Typography style={{color:"black"}}>{currentWidth}</Typography> */}

        <div className='partner_logo_container'  style={{ height:originalDetail.length === 1 ? "250px" : "250px" , width: restaurants.length === 1 ? "500px" :  restaurants.length === 2  &&  currentWidth >= 840 ? "800px" : "100%"}}>
                                                                                                                                                                                                                                                                                                                       {/* pagination={ currentWidth < 640  && restaurants.length > 0 && originalDetail.length > 1 ? true : false} */}
            <Carousel  arrowSize={10} itemsToShow={currentWidth < 540 ? 1 : restaurants.length === 1 ? 1 : currentWidth < 567  &&  restaurants.length === 2 ? 1 : restaurants.length === 2 ? 2 :  currentWidth < 767  && restaurants.length === 4 ? 2 :  restaurants.length === 4 ? 3 :  3} showArrows={restaurants.length > 1 && currentWidth <= 767 || restaurants.length >= 4} pagination={false} >
            {restaurants.map((item, index) => { 
            return (
              <div className='sub_con' style={{height: originalDetail.length === 1 && "180px",paddingBottom:originalDetail.length === 1 && "20px",padding:0,justifyContent: originalDetail.length > 5 ? "space-between" : "center"}} key={index}>
                
                {originalDetail.length <= 5 ?  <div style={{padding:20, height:originalDetail.length === 1 ? "150px" : "50%",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => navigate(`/promotion/${item?.id}`)}>
                    <div className='partner_logo' style={{backgroundImage:`url(${getImageUpdload(item?.res_logo)})`,padding:10,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:"center",width:'100%',height:'100%'}}></div>
                 </div> : 
                 <>
                    {item.item_1?.res_logo && 
                        <div style={{padding:20,borderBottom:"0.5px solid rgba(230, 224, 224,.4)", height:originalDetail.length === 1 ? "150px" : "50%",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => navigate(`/promotion/${item.item_1?.id}`)}>
                            <div className='partner_logo' style={{backgroundImage:`url(${getImageUpdload(item.item_1?.res_logo)})`,padding:10,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:"center",width:'100%',height:'100%'}}></div>
                        </div>
                    }
                    {item.item_2?.res_logo && 
                        <div style={{padding:20,height:"50%",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => navigate(`/promotion/${item.item_2?.id}`)}>
                          <div className='partner_logo' style={{backgroundImage:`url(${getImageUpdload(item.item_2?.res_logo)})`,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:"center",width:'100%',height:'100%'}} onClick={() => navigate(`/promotion/${item.id}`)}></div>
                        </div>
                    }
                </>
                }
                 
              </div>
            );
           })}
           </Carousel>
            {/* <Carousel
            showArrows={true}
            showThumbs={false} // Remove the dots
            showStatus={false}
            emulateTouch={true}
            centerMode={true}
            centerSlidePercentage={25} // Each slide will contain 25% of the carousel
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && <CustomLeftArrow onClick={onClickHandler} />
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && <CustomRightArrow onClick={onClickHandler} />
            }
            //  width={"400px"}  // Set carousel width to fill the container
          >

           {restaurants.map((item, index) => {
            return (
              <div className='sub_con' key={index}>
                {item.item_1?.res_logo && 
                <div style={{padding:20,height:"50%",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => navigate(`/promotion/${item.item_1?.id}`)}>
                  <div className='partner_logo' style={{backgroundImage:`url(${getImageUpdload(item.item_1?.res_logo)})`,padding:10,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:"center",width:'100%',height:'100%'}}></div>
                </div>
                }
                {item.item_2?.res_logo && <div style={{padding:20,borderTop:"0.5px solid rgba(230, 224, 224,.4)",height:"50%",display:'flex',justifyContent:"center",alignItems:'center'}} onClick={() => navigate(`/promotion/${item.item_2?.id}`)}>
                   <div className='partner_logo' style={{backgroundImage:`url(${getImageUpdload(item.item_2?.res_logo)})`,backgroundSize:'contain',backgroundRepeat:'no-repeat',backgroundPosition:"center",width:'100%',height:'100%'}} onClick={() => navigate(`/promotion/${item.id}`)}></div>
                   </div>
                }
                 
              </div>
            );
           })}
            </Carousel> */}

        </div>
        </div>
      

        <div className='homepage_second_container'>
                <div className="headlines">
                    <Typography className="first">Streamlined Dining Solutions</Typography>
                    <Typography className="second">Comprehensive QR Order System Services</Typography>
                    <Typography className='third'>Effortless Ordering, Seamless Integration, and Enhanced Customer Satisfaction – Explore Our Range of Solutions Today</Typography>
                </div>
                <div className='section_container'>
              
                <div className='left_side'>
                    <div className='first_level'></div>
                
                </div>
                
                <div className='right_side'>
                  {listing.map((item)=>{
                    return   <div className="listing">
                    <img className='listing_img' src={item.image}/>
                    <div className='listing_desc'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='desc'>{item.desc}</Typography>
                    </div>
                  </div>
                  })}
                  
                </div>

                </div>
              <div className='section_container2'>
                    
                <div className='left_side'>
                {listing2.map((item)=>{
                    return   <div className="listing">
                    <img className='listing_img' src={item.image}/>
                    <div className='listing_desc'>
                      <Typography className='label'>{item.label}</Typography>
                      <Typography className='desc'>{item.desc}</Typography>
                    </div>
                  </div>
                  })}
                    
                </div>
                
                <div className='right_side'>
                    <div className='first_level'></div>
                  
                </div>
              </div>
        </div>

        <div className='homepage_third_container'>
               
                <div className='section_1'>
                    <Typography className='first'>Implementing a QR <br/> Order System</Typography>
                    <Typography className='second'>Offers advantages for both the <br/> establishment and its customers</Typography>
                    <Typography className='label'>Enhanced Customer Experience</Typography>
                    <Typography className='desc'>Streamlines the ordering process, allowing customers to browse the menu, place orders, and make payments conveniently from their smartphones.</Typography>
                    <Typography className='label'>Improved Efficiency</Typography>
                    <Typography className='desc'>Automates the order-taking process, eliminating the need for manual order entry and reducing the risk of errors.</Typography>
                    <Typography className='label'>Increased Revenue</Typography>
                    <Typography className='desc'>Encourages impulse purchases by showcasing high-quality images and enticing descriptions of menu items.</Typography>
                </div>
        </div>
        <Footer />
        {isLoading && <Loader />} 
        </div>
      
      
      </div>
    </>
   
 
  )
}

export default HomePage
