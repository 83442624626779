import React from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { ErrorPage,HomePage,ContactUs,Queueng ,PromotionPage,Reservations} from '../Pages';

export default function HomeNaviation() {
  
const navigate = useNavigate();
  return (
   <Routes navigate={navigate}>
        <Route exact path="/" element={<ProtectedRoute><HomePage/></ProtectedRoute> }/>
        <Route exact path="/contact-us/" element={<ProtectedRoute><ContactUs/></ProtectedRoute> }/>
        <Route exact path="/contact-us/:form" element={<ProtectedRoute><ContactUs/></ProtectedRoute> }/>
        <Route exact path="/promotion/:restaurant_id" element={<ProtectedRoute><PromotionPage/></ProtectedRoute> }/>
        <Route exact path="/reservations/:restaurant_id/:branch_id" element={<ProtectedRoute><Reservations/></ProtectedRoute> }/>
        {/* <Route exact path="/queueng" element={<ProtectedRoute><Queueng/></ProtectedRoute> }/> */}
         <Route path="*" element={<ErrorPage />} />  
    </Routes>
  )
}
