import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ children, isLanding }) => {
    const location = useLocation();
    const userData = useSelector((state) => state.user.userData);
    console.log('Session --------------> ', userData)

    // if (userData === null) {
    //     return <Navigate to={{ pathname: "/", state: { from: location } }} />;
    // } else {
        return children;
    //}
};