import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userData: null,
  currentTheme:3,
  table_id:null,
  order_id:null,
  restaurant_id:null,
  list_order_id:[], //store the order ids here?
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setTableID: (state,action) =>{
      state.table_id = action.payload
    },
    setOrderID : (state,action) =>{
      state.order_id = action.payload
    },
    setListOrderId : (state,action) =>{
      state.list_order_id = action.payload
    },
    setRestaurentId : (state,action) =>{
      state.restaurant_id = action.payload
    }
  },
})

export const { setUserData, setTableID, setOrderID ,setListOrderId, setRestaurentId} = slice.actions

export default slice.reducer