import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  restaurantData: null,
}

const slice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setRestaurantData: (state, action) => {
      state.restaurantData = action.payload
    },
  },
})

export const { setRestaurantData } = slice.actions

export default slice.reducer