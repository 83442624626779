import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../Assets/loader.json';
import { Modal } from '@mui/material';
import "./styles.scss";

const Loader = () => {
    const [height, setHeight] = useState(450);
    const [width, setWidth] = useState(450);
    const [open, setOpen] = useState(true); // State to manage the modal open/close

    const windowWidth = window.innerWidth;

    useEffect(() => {
        if (windowWidth < 640) {
            setWidth(250);
            setHeight(250);
        } else {
            setWidth(450);
            setHeight(450);
        }
     
    }, [windowWidth]);

    const handleClose = () => {
        setOpen(false); // Close the modal when triggered
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="loader-modal-title"
            aria-describedby="loader-modal-description"
            className="loader_modal"
        >
            <div className='loader_container'>
                <Lottie
                    options={defaultOptions}
                    height={height}
                    width={width}
                />
            </div>
        </Modal>
    );
};

export default Loader;
